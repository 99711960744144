.card-bx{
	position:relative;
	z-index: 1;
	border-radius:$radius;
	overflow:hidden;
	padding: 0px 35px;
	display:flex;
	.card-info{
		flex:1;
		h2{
			font-size:36px;
		}
	}
	.change-btn{
		writing-mode: tb;
		position:relative;
		font-weight:600;
		background:$white;
		color:$black;
		border-radius:1.25rem;
		transform: rotate(-180deg);
		font-size:17px;
		padding:20px 23px;
		.reload-icon{
			height:53px;
			width:53px;
			line-height:53px;
			background:#fafafa;
			color:#969ba0;
			border-radius:1.25rem;
			text-align:center;
			font-size:20px;
			margin:10px -15px -10px;
			float:right;
			@include transitionMedium;
			i{
				@include transitionSlow;
			}
		}
		.up-ico{
			margin-bottom: 20px;
			color:#9fa4a6;
		}
		&:after{
			content:"";
			position:absolute;
			right:10px;
			z-index: -1;
			top:50%;
			transform:translateY(-50%);
			width:100%;
			height:90%;
			border-radius:inherit;
			background:rgba($white,0.3);
		}
	}
	.pattern-img{
		z-index: -1;
		position:absolute;
		height:100%;
		width:100%;
		object-fit:cover;
		content:"";
		top: 0;
		left: 0;
	}
	@include respond ('phone'){
		padding: 15px 15px;
		.card-info{
			padding-top:15px;
			h2{
				font-size:30px;
			}
		} 
		.change-btn{
			padding: 15px 15px;
		}
	}
}
.description-bx{
	.card-bx{
		width:416px;
		min-width:416px;
		.card-info{
			padding:30px 0; 
		}
		@include respond ('phone'){
			width:100%;
			min-width:100%;
		}
	}
}
.description{
	font-size:0.875rem;
	color:$black;
	font-weight:400;
}
.payment{
	i{
		color:#4F7086;	
	}
}
.transaction-details{
	border-top:1px solid #e4e4e4;
	.user-bx-2{
		display:flex;
		align-items:center;
		img{
			height:85px;
			width:85px;
			margin-right:20px;
		}
		&>div{
			span{
				font-size:1rem;
				color:#464646;
			}
		}
		
	}
	.amount-bx{
		width:330px;
		display:flex;
		padding:20px;
		align-items:center;
		border-radius:18px;
		i{
			height:54px;
			width:54px;
			font-size:25px;
			margin-right:20px;
			border-radius:1rem;
			line-height:54px;
			text-align:center;
			background: rgba(255, 170, 43, 1);
			color:$white;
		}
		h3{
			font-size:24px;
			font-weight:800;
		}
	}
	h4{
		font-size:18px;
		font-weight:700;
	}
}
.bg-purple{
	background:#097394;	
}
.card-balance{
	font-size:32px;
	font-weight:800;
}
.specifics-list{
	display: flex;
    flex-flow: wrap;
	li{
		width:50%;
		float: left;
		display: flex;
		margin-top: 25px;
		&>span{
			height:30px;
			width:10px;
			border-radius:10px;
			display:inline-block;
			margin-right:15px;
		}
		h4{
			font-size:20px;
			font-weight:700;
		}
	}
}