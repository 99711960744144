.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    border-bottom-color: $secondary;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before{
    border-right-color: $secondary;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before{
	border-top-color: $secondary;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before{
	border-left-color: $secondary;
}

.popover{
    border: 0.125rem solid $secondary;
    min-width: 13.125rem;
    box-shadow: 0 0 1.875rem 0 rgba(0,0,0,0.1);
	@at-root [data-theme-version="dark"] & {
        background-color: $d-bg;
    }
    &-header{
        background: $secondary;
        color: $white;
        font-weight: 300;

        &::before{
            border-bottom: 0 !important;
        }
    }

    &-body{
        font-size: 0.75rem;
    }
	.popover-header{
		@at-root [data-theme-version="dark"] & {
			border-color: $dark-card;
		}
    }
	
	.arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
	
	@include respond ('phone-land'){
		z-index:1;
	}
}

.bootstrap-popover-wrapper{
    .bootstrap-popover:not(:last-child){
        margin-right: 0.5rem;
    }

    .bootstrap-popover{
        margin-bottom: .5rem;

        button:hover,button:focus{
            background: var(--primary);
            color: $white;
            box-shadow: none;
        }
    }
}

// .tooltip {
//     font-size: 0.9375rem;
// }


/* .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 0rem;
    border-top-color: $secondary;
}


.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 0rem;
    border-left-color: $secondary;
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 0rem;
    border-right-color: $secondary;
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 0rem;
    border-bottom-color: $secondary;
} */

.note-popover{
    display: none;
}



.bs-popover-bottom {
  > .arrow {
    top: subtract(-$popover-arrow-height, $popover-border-width);

    &::before {
      top: 0;
      border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-outer-color;
    }

    &::after {
      top: $popover-border-width;
      border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-color;
    }
  }
}

.bs-popover-top {
  > .arrow {
    bottom: subtract(-$popover-arrow-height, $popover-border-width);

    &::before {
      bottom: 0;
      border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-top-color: $popover-arrow-outer-color;
    }

    &::after {
      bottom: $popover-border-width;
      border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-top-color: $popover-arrow-color;
    }
  }
}

.bs-popover-right {
  > .arrow {
    left: subtract(-$popover-arrow-height, $popover-border-width);
    width: $popover-arrow-height;
    height: $popover-arrow-width;

    &::before {
      left: 0;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-outer-color;
    }

    &::after {
      left: $popover-border-width;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-color;
    }
  }
}

.bs-popover-left {
  > .arrow {
    right: subtract(-$popover-arrow-height, $popover-border-width);
    width: $popover-arrow-height;
    height: $popover-arrow-width;

    &::before {
      right: 0;
      border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2) $popover-arrow-height;
      border-left-color: $popover-arrow-outer-color;
    }

    &::after {
      right: $popover-border-width;
      border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2) $popover-arrow-height;
      border-left-color: $popover-arrow-color;
    }
  }
}


.toltip-popover{
	.tooltip-inner{
		background: #ffffff;    
		padding: 0;
		border-top-left-radius: calc(0.3rem - 1px);
		border-top-right-radius: calc(0.3rem - 1px);
		border: 0.125rem solid #4CBC9A;
		strong{
			color: #777777;
		}
	}
}