

//sidebar styles
[direction="rtl"] {
	text-align: right;
	
    .dlabnav {
        text-align: right;
        
        .metismenu {
			ul{
			
				&:after{
					left:auto;
					right:1.5625rem;
				}
				a:before{
					left:auto;
					right: 2.625rem;
				}
			}
			li{
				& > a{ 
					
					i{
						@at-root [data-sidebar-style="full"]#{&} {
							padding: 0 0 0 0;
							margin-right: 0;
							margin-left: 0.625rem;
						}
					}
					svg{
						margin-left:0.3125rem;
						margin-right:0;
						@at-root [data-sidebar-style="compact"]#{&} {
							left: auto;
							margin-left: auto;
							margin-right: auto;
						}
						@at-root [data-sidebar-style="icon-hover"]#{&} {
							margin-left:0;
						}
					}
				}
				ul a{
					padding-right: 6rem;
					padding-left: 0.625rem;
				}
			}
            li.active {

                &>.has-arrow {
                    &:after {
                        transform: rotate(45deg) translateY(-50%);
                    }
                }

            }

            .has-arrow {
                &:after {
                    left: 1.5625rem;
                    right: auto;
					
					@at-root [data-layout="horizontal"]#{&} {
						left: 1.125rem;
					}
					@at-root [data-sidebar-style="modern"]#{&} {
						-webkit-transform: rotate(-45deg) translateY(-50%);
						transform: rotate(-45deg) translateY(-50%);
					}
                }
            }

        }
    }

	&[data-sidebar-style="full"][data-layout="vertical"]{
		.dlabnav .metismenu > li .has-arrow:after {
			left: 1.5rem;
			right: auto;
		}
	}
	&[data-sidebar-style="mini"]{
		.dlabnav .metismenu > li > a > i {
			padding: 0;
		}
		&[data-layout="vertical"]{
			.dlabnav .metismenu > li > ul a.has-arrow:after{
				left: 1.5625rem;
				right: auto;
			}
		}
	}
	&[data-sidebar-style="compact"] {
		.dlabnav{
			.metismenu li{
				& > a i {
					padding: 0;
					margin-left:auto;
					margin-right:auto;
				}
				ul a {
					padding-right: 0.625rem;
					padding-left: 0.625rem;
				}
			}
		}
	}
    &[data-sidebar-style="full"][data-layout="vertical"] {
        .menu-toggle {
            .dlabnav {
                .metismenu {
                    li {
                        &>ul {
    
                            li:hover {
                                ul {
                                    right: 11.8125rem;
                                    left: 0;
									
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

