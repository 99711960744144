@media (min-width: 62rem) {
    [data-container="boxed"] {
        #main-wrapper {
            max-width: 74.9375rem;
            margin: 0 auto;
			box-shadow: 0 0 1.875rem 0 rgba(0,0,0,0.1);
				
            @at-root [direction="rtl"]#{&} {
                text-align: right;
            }
        }
		.invoice-num {
			font-size: 1.375rem;
		}
    }
}

    
    
@include custommq($min: 84.375rem) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] {
        .header {
            width: 74.9375rem;
        }
    }
}

@include custommq($min: 75rem, $max: 84.3125rem) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] {
        .header {
            width: 74.9375rem;
        }
    }
}


[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] {
    .dlabnav {
        max-width: 74.9375rem;
    }
} //ok

[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"],
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] {
    .header {
        width: 74.9375rem;
    }
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] {
    .header {
        width: 74.9375rem;
    }
}


[data-container="boxed"] {
    .metismenu.fixed {
        left: auto;
        max-width: 74.9375rem;
    }

    .page-titles {
        margin-bottom: 3rem;
        padding: 0.9375rem 0.9375rem;
    }

    .content-body .container-fluid {
        padding: 2.5rem;
    }
}

[data-container="boxed"][data-layout="vertical"] {
    .page-titles {
        margin-left: 0;
        margin-right: 0;
    }
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] {
    .nav-header {
        position: absolute;
    }

    .menu-toggle {
        .dlabnav {
            position: absolute;
        }
    }
}

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] {
    .dlabnav.fixed {
        left: auto;
        max-width: 74.9375rem;
    }
}